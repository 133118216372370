var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-10",attrs:{"width":"1300","height":"890","max-height":"890"}},[_c('v-row',[_c('v-col',[_c('v-slider',{attrs:{"label":_vm.slider.label,"thumb-color":_vm.slider.color,"thumb-label":"always","max":"100000"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"80px"},attrs:{"type":"number"},model:{value:(_vm.slider.val),callback:function ($$v) {_vm.$set(_vm.slider, "val", $$v)},expression:"slider.val"}})]},proxy:true}]),model:{value:(_vm.slider.val),callback:function ($$v) {_vm.$set(_vm.slider, "val", $$v)},expression:"slider.val"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"elevation":"2"},on:{"click":function($event){return _vm.computing()}}},[_vm._v(" Compute ")])],1)],1),_c('v-row',[_c('v-card-text',[_vm._v(" Reached level : "+_vm._s(this.reachedLevel)+" "),_c('br'),_vm._v(" Median (count completed/game level) : "+_vm._s(this.median)+" "),_c('br'),_c('br'),_vm._v(" Here not include heroBaseDps, supportLevelReached, totalGoldOnLevelReach in simulator ")]),_c('v-card-title',[_vm._v(" Total reward: "+_vm._s(this.totalReward)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{attrs:{"items":_vm.itemFilterSelect,"chips":"","clearable":"","label":"Filter by tags","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeItemFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.text))]),_vm._v("  ")])]}}]),model:{value:(_vm.itemFilterChosen),callback:function ($$v) {_vm.itemFilterChosen=$$v},expression:"itemFilterChosen"}})],1)],1),_c('v-virtual-scroll',{attrs:{"height":500,"item-height":"30","items":this.completedList.slice().reverse(),"id":"achievement-scroller"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-5"},[_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-col',[_vm._v(" - "+_vm._s(item.questTitleCode)+" (level:"+_vm._s(item.level)+") ")])],1)],1)]}}])})],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }