<template>
    <v-app>
        <v-card
            min-height="600"
            max-height="600"
        >

            <v-card-title>
                Achievement List

            </v-card-title>
            <v-dialog
                v-model="showSimulation"
                scrollable
                @click:outside="close()"
            >
                <AchievementSimulation v-if="showSimulation" :pAchievementList="this.pAchievementList" @close="close()"/>
            </v-dialog>




            <v-dialog
                v-model="showDetail"
                max-width="900px"
                scrollable
                @click:outside="close()"
            >
                <AchievementListViewDetails
                    v-if="showDetail"
                    :pTitle="this.$get(selectedRow, 'name')"
                    :pSelectedItem="this.selectedRow"
                />
            </v-dialog>


            <v-card
                max-height="600" class="overflow-y-auto"
            >
                <v-container>
                    <v-row>
                        <v-col>
                            <v-data-table

                                :headers="headers"
                                :items="achievementList"

                                class="elevation-1 petList"

                                hide-default-footer

                                :options="{itemsPerPage:200}"
                                :search="search"
                                show-group-by
                                @click:row="doClickOnRow"
                            >



                                <template v-slot:item.baseGoal="{item}">
                                    <v-edit-dialog
                                        :return-value.sync="item.baseGoal"
                                    >

                                        {{ item.baseGoal }}

                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="item.baseGoal"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>

                                </template>


                                <template v-slot:item.jumpingLevel="{item}">
                                    <v-edit-dialog
                                        :return-value.sync="item.jumpingLevel"
                                    >

                                        {{ item.jumpingLevel }}

                                        <template v-slot:input>
                                            <v-text-field
                                                v-model="item.jumpingLevel"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>

                                </template>

                                <template v-slot:item.cumulation="{item}">
                                    <v-edit-dialog
                                        :return-value.sync="item.cumulation"
                                        @save="save"
                                        large
                                        persistent
                                    >

                                        {{ item.cumulation }}

                                        <template v-slot:input>
                                            <v-checkbox
                                                v-model="item.cumulation"
                                                :label="`${item.cumulation.toString()}`"
                                            ></v-checkbox>
                                            <!--                                <v-text-field-->
                                            <!--                                        v-model="item.cumulation"-->
                                            <!--                                        label="Edit"-->
                                            <!--                                        single-line-->
                                            <!--                                        autofocus-->
                                            <!--                                        @focus="$event.target.select()"-->

                                            <!--                                ></v-text-field>-->
                                        </template>
                                    </v-edit-dialog>

                                </template>

                            </v-data-table>


                        </v-col>

                    </v-row>
                </v-container>



            </v-card>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
                <v-btn color="green darken-1" text @click="dispatchSave()">Save</v-btn>
<!--                <v-btn color="green darken" text @click="openSimulation()">SIMULATE</v-btn>-->
            </v-card-actions>
        </v-card>
    </v-app>
</template>

<script>
import SMPGameplayAchievement from "@/achievement-simulation/models/SMPGameplayAchievement";
import lodash from "lodash";
import AchievementSimulation from "@/achievement-simulation/screen/AchievementSimulation.vue";
import AchievementListViewDetails from "@/achievement-simulation/components/AchievementListViewDetails.vue";

export default {
    name: "AchievementListView",
    data() {
        return {
            showSimulation: false,
            showDynamic: true,
            selectedRow: null,
            search: "",
            achievementList: [],
            showDetail: false,
            cumulatedReward: 0,
            headers: [
                {text: 'ID', value: 'id', groupable: false},
                {text: 'Achievement', value: 'name', groupable: false},
                {text: 'Basegoal', value: 'baseGoal', groupable: false},
                {text: 'Unit', value: 'unit', groupable: false},
                {text: 'Mode', value: 'mode', groupable: false},
                {text: 'Jumping', value: 'jumpingLevel', groupable: false},
                {text: 'Formula', value: 'formula', groupable: false},
                {text: 'Cumulation', value:'cumulation', groupable: true}

            ],
        }
    },

    props: {
        'pAchievementList': {
            type: Array,
            required: true
        },
        'shouldInit': {
            type: Boolean,
        }
    },

    methods: {

        loadData: function (list) {
            this.achievementList = [];
            if (Array.isArray(list)) {
                list.forEach(e => {
                    let clone = lodash.cloneDeep(e);
                    let achievement = new SMPGameplayAchievement(clone, this.$store.state.gamePlayDataService);
                    this.achievementList.push(achievement);
                });
            }
        },

        save : function(e) {
            return e;
        },

        didChange: function () {
            this.loadData(this.pAchievementList);
        },

        dispatchClose: function () {
            this.$emit('close');
            this.$router.push('/');
        },

        dispatchSave: async function () {
            await this.$store.commit('pushAchievements',this.achievementList);
        },
        openSimulation: async function () {
            this.showSimulation = true;
        },

        // eslint-disable-next-line no-unused-vars
        didUpdateAchievementList: function (list) {
        },

        // eslint-disable-next-line no-unused-vars
        doClickOnRow : function(item,slot) {
            this.selectedRow = item;
            this.showDetail = true;
        },

        close : function() {
            this.showDetail = false;
            this.showSimulation = false;
        }
    },
    created: function () {
        this.loadData(this.pAchievementList);
    },

    components: {AchievementListViewDetails, AchievementSimulation},

    watch: {
        pAchievementList: {
            deep: true,
            handler: 'didChange'
        },

        shouldInit: {
            handler: 'didChange'
        },

        achievementList: {
            deep: true,
            handler: 'didUpdateAchievementList'
        }
    },

}
</script>


<style lang="scss">
.petList tbody {
    tr .standard:hover {
        background-color: darkgray !important;
    }
}

.standard {
    background-color: transparent;
}

.selected {
    background-color: dodgerblue !important;
}

</style>