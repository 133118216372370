<template>
    <v-card>
        <v-card-title>{{this.pTitle}} - Will cumulate {{detailAchievement.totalReward}} diamond</v-card-title>
        <v-data-table

            :headers="headersDetail"
            :items="detailAchievement.list"

            class="elevation-1 petList"

            hide-default-footer

            :options="{itemsPerPage:200}"
            :search="search"
            show-group-by
        >


        </v-data-table>
    </v-card>
</template>

<script>
import AchievementConstant from "@/constants/AchievementIdConstants";

export default {
    name: "AchievementListViewDetails",
    props: ['pTitle', 'pSelectedItem'],
    data (){
        return {
            search: "",
            headersDetail: [
                {text: 'Level', value: 'level', groupable: false},
                {text: 'Challenge', value: 'challenge', groupable: false},
                {text: 'Reward', value: 'reward', groupable: false}
            ],
        }
    },

    computed : {
        detailAchievement : function() {
            let achievement = this.pSelectedItem;
            let totalCompletions = 30;
            let mapping = [];

            let cumulatedReward = 0;
            if (achievement) {
                achievement.reset();

                if (achievement.id === AchievementConstant.UNLOCK_HEROES) {
                    totalCompletions = 8;
                }

                if (achievement.id === AchievementConstant.UNLOCK_SUPPORT){
                    totalCompletions = 21; //only 20 will display
                }

                for (let nthComplete = 1; nthComplete < totalCompletions; nthComplete++) {


                    let content = {};
                    content.level = achievement.currentLevel;
                    content.reward = achievement.getDiamondsForAchievementColletMoney();
                    content.challenge = achievement.challenge;

                    if (achievement.id === AchievementConstant.COLLECT_GOLD
                        || achievement.id === AchievementConstant.REACH_HEROES_DPS
                    ) {
                        content.challenge = achievement.challenge.ToReadableAlphabetV2();
                    }
                    mapping.push(content);

                    cumulatedReward = cumulatedReward + content.reward;

                    if (nthComplete > 0) {
                        achievement.collectAchievement();
                    }
                }
                achievement.reset();
            }

            return {list:mapping,totalReward:cumulatedReward};
        }
    },
}
</script>

<style scoped>

</style>