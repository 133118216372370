import { render, staticRenderFns } from "./AchievementListViewDetails.vue?vue&type=template&id=2f05a9b6&scoped=true&"
import script from "./AchievementListViewDetails.vue?vue&type=script&lang=js&"
export * from "./AchievementListViewDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f05a9b6",
  null
  
)

export default component.exports