<template>
    <div>
        <v-card width="1300" height="890" max-height="890" class="pa-10">
            <v-row>
                <v-col>
                    <v-slider
                        v-model="slider.val"
                        :label="slider.label"
                        :thumb-color="slider.color"
                        thumb-label="always"
                        max="100000"
                    >
                        <template v-slot:append>
                            <v-text-field
                                v-model="slider.val"
                                class="mt-0 pt-0"
                                type="number"
                                style="width: 80px"
                            ></v-text-field>
                        </template>

                    </v-slider>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        elevation="2"
                        @click="computing()"
                    >
                        Compute
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-card-text>
                    Reached level : {{this.reachedLevel}} <br/>
                    Median (count completed/game level) : {{this.median}} <br/>
                    <br/>
                    Here not include heroBaseDps, supportLevelReached, totalGoldOnLevelReach in simulator
                </v-card-text>
                <v-card-title>
                    Total reward: {{ this.totalReward }}
                </v-card-title>
            </v-row>
            <v-row>

                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-combobox
                        v-model="itemFilterChosen"
                        :items="itemFilterSelect"
                        chips
                        clearable
                        label="Filter by tags"
                        multiple
                        solo
                    >

                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeItemFilterChosen(item)"
                            >
                                <strong>{{ item.text }}</strong>&nbsp;
                            </v-chip>
                        </template>

                    </v-combobox>
                </v-col>
            </v-row>

            <v-virtual-scroll
                    :height="500"
                    item-height="30"
                    :items="this.completedList.slice().reverse()"
                    id="achievement-scroller"
            >
                <template v-slot:default="{ item }">
                    <div class="pt-5">
                        <v-row dense justify="center">
                            <v-col>
                                - {{ item.questTitleCode }} (level:{{ item.level }})
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-virtual-scroll>

<!--            <v-card height="500" max-height="500" class="scroll pl-10" elevation="0">-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <li v-for="item in this.completedList.slice().reverse()" :key=" item.id + item.questTitleCode + item.level">-->
<!--&lt;!&ndash;                            {{ item.questTitleCode }} (level:{{ item.level }}, kpi:{{ item.KPIGameplayBossLevel }})&ndash;&gt;-->
<!--                            {{ item.questTitleCode }} (level:{{ item.level }})-->
<!--                        </li>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-card>-->


        </v-card>

        <v-overlay :value="isLoading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>

        </v-overlay>
    </div>
</template>

<script>
// import SMPNum from "@/SMPNum";
import lodash from "lodash";
import SMPGameplayAchievement from "@/achievement-simulation/models/SMPGameplayAchievement";
import AchievementSimulationService from '@/achievement-simulation/services/AchievementSimulationService';
import SupportKpiUtils from "@/kpi-editor/Utils/SupportKpiUtils";
import SupportSkillsConstant from "@/constants/SupportSkillsConstants";
import BalanceKpiUtils from "@/kpi-editor/Utils/BalanceKpiUtils";
import PetKpiUtils from "@/kpi-editor/Utils/PetKpiUtils";
import HeroSkillKpiUtils from "@/kpi-editor/Utils/HeroSkillKpiUtils";

export default {
    name: "AchievementSimulation",
    props: {
        'pAchievementList': {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            achievementList:[],
            reachedLevel:0,
            median: 0,
            isLoading: false,
            itemFilterChosen: [],
            itemFilterSelect: [],
            slider: {label: 'Level', val: 1, color: 'red'},
            originalList: [],
            completedList: [],
            totalReward: null,

            gamePlayDataService: {},
            balanceKpiUtils: {},
            supportKpiUtils: {},
            petKpiUtils: {},
            heroSkillKpiUtils: {},
            balanceBuilder: {},
        }
    },
    methods: {
        removeItemFilterChosen(item) {
            this.itemFilterChosen.splice(this.itemFilterChosen.indexOf(item), 1)
        },
        async computing() {
            let gameLevel = this.slider.val;
            let filter = this.itemFilterChosen;
            let heroBaseDps = null;
            let totalSupportLevel = null;//supportLevel.ToDoubleValue() * supportCount
            let achievementList = this.achievementList;
            let totalGoldOnLevel = null;

            this.computeAchievements(gameLevel, filter, heroBaseDps, totalSupportLevel, achievementList, totalGoldOnLevel);

            /*old
            let service = new AchievementSimulationService(
                lodash.cloneDeep(this.achievementList),
                this.balanceBuilder,
                this.gamePlayDataService,
                this.supportKpiUtils
            );
            let result = service.compute(this.slider.val, this.itemFilterChosen, null, null, null, null);
            this.totalReward = result.reward;
            this.median = result.median;
            this.reachedLevel = result.reachedLevel;
            this.completedList = result.completedData;*/
        },

        computeAchievements(gameLevel, filters, heroBaseDps, totalSupportLevel, achievementList, totalGoldOnLevelReach){
            let service = new AchievementSimulationService(
                lodash.cloneDeep(achievementList),
                this.balanceBuilder,
                this.gamePlayDataService,
                this.supportKpiUtils
            );


            const result = service.compute(
                gameLevel,
                filters,
                heroBaseDps,
                totalSupportLevel,
                totalGoldOnLevelReach
            );

            // return result.reward
            this.totalReward = result.reward;
            this.median = result.median;
            this.reachedLevel = result.reachedLevel;
            this.completedList = result.completedData;
        },

        initAchievement(list) {
            this.achievementList = [];
            if (Array.isArray(list)) {
                list.forEach(e => {
                    let clone = lodash.cloneDeep(e);
                    let achievement = new SMPGameplayAchievement(clone, this.gamePlayDataService);
                    this.achievementList.push(achievement);
                });
            }
        },
        initFilterSelect () {
            this.achievementList.forEach(item => {
                this.itemFilterSelect.push({text: item.name, value: item.id});
            });

        },

    },

    created() {
        this.totalReward = 0;

        this.gamePlayDataService = this.$store.state.gamePlayDataService;
        this.balanceKpiUtils = new BalanceKpiUtils(this.gamePlayDataService);
        this.supportKpiUtils = new SupportKpiUtils(this.$store.state.skillList, this.gamePlayDataService, SupportSkillsConstant);
        this.petKpiUtils = new PetKpiUtils(this.$store.state.petsData, this.gamePlayDataService);
        this.heroSkillKpiUtils = new HeroSkillKpiUtils(this.$store.state.heroSkillList, this.gamePlayDataService);
        this.balanceBuilder = this.$store.state.balanceBuilder;

        this.initAchievement(this.pAchievementList);

        this.initFilterSelect();


    },
}
</script>

<style scoped>

</style>